<template>
  <div class="wrapper" id="login_wrapper">
    <p class="title">让输注更便捷</p>
    <img class="bg_img" :src="require('./login_bg@2x.png')" alt="" />
    <div class="login_form">
      <p class="text">登录</p>
      <div class="phone_wrapper item_wrapper">
        <van-field
          v-model="phoneValue"
          label="手机号"
          placeholder="请输入手机号"
          input-align="right"
          clearable
          clear-trigge="always"
          type="tel"
          maxlength="11"
          :error-message="phoneErrorMessage"
          error-message-align="right"
          @blur="handlePhoneBlur"
        />
      </div>
      <div class="verify_code_wrapper item_wrapper">
        <van-field
          v-model="sms"
          center
          input-align="right"
          clearable
          label="短信验证码"
          placeholder="请输入验证码"
          type="number"
        >
          <template #button>
            <span class="btn" @click="handleGetSms">{{
              sendTag ? `${count}s` : '获取验证码'
            }}</span>
          </template>
        </van-field>
      </div>
    </div>

    <bottom-btns
      :btns="[
        {
          text: '登录',
          type: 'primary',
          eventName: 'login',
        },
      ]"
      @login="handleLogin"
      :slot="true"
    >
      <div class="agreement_wrapper">
        <van-checkbox v-model="agreementChecked"
          ><span>同意</span
          ><span class="agreement" @click.stop="handleClickAgreement(0)"
            >《平台服务协议》</span
          ><span>及</span
          ><span class="agreement" @click.stop="handleClickAgreement(1)"
            >《隐私政策》</span
          ><span>条款并注册</span></van-checkbox
        >
      </div>
    </bottom-btns>

    <van-action-sheet v-model="show" title="派输注协议及政策">
      <AgreementContent :active="active" />
    </van-action-sheet>
  </div>
</template>

<script>
import { Toast } from 'vant';
import BottomBtns from '@/components/bottom-btns/index.vue';
import api from '@/api';
import Configs from '@/config';
import AgreementContent from '@/components/agreementContent'

export default {
  name: 'login',
  components: {
    BottomBtns,
    AgreementContent
  },
  data () {
    return {
      active: 1,
      show: false,
      phoneErrorMessage: '',
      sendLoading: false,
      sendTag: false,
      count: 0, // 倒计时秒数
      phoneValue: '',
      sms: '',
      // 是否在倒计时
      countDown: false,
      timer: null,
      code: '',
      agreementChecked: false,
    }
  },
  created () {
    if (this.getQueryStringArgs().code) {
      // 有code了
      this.code = this.getQueryStringArgs().code
    } else {
      // 没有code
      const url = encodeURIComponent(`${Configs.loginUrl}`)
      // 跳转到微信授权页面，授权获取code
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${Configs.appId}&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=psz#wechat_redirect`
    }
  },

  mounted () {
    // 填充背景色
    const height = document.body.clientHeight
    const wrapperRef = document.getElementById('login_wrapper')
    wrapperRef.style.height = height + 'px'
  },

  methods: {
    handleClickAgreement (tab) {
      this.active = tab
      this.show = true
    },
    validatePhoneNumber (number) {
      const regEx = /^[1][3-9][0-9]{9}$/;
      return regEx.test(number);
    },
    handlePhoneBlur () {
      this.phoneErrorMessage = this.validatePhoneNumber(this.phoneValue) ? '' : '请输入正确的手机号'
    },

    // 获取url query参数方法
    getQueryStringArgs () {
      const qs = location.search.length > 0 ? location.search.substring(1) : ''
      const args = {};
      const arr = qs.split('&').map((kv) => kv.split('='));
      arr.forEach((item) => {
        args[item[0]] = item[1];
      });
      return args;
    },

    // 登录方法
    async handleLogin () {
      if (!this.phoneValue || !this.sms) {
        return Toast.fail('请输入手机号验证码')
      }

      if (!this.agreementChecked) {
        return Toast.fail('请阅读并同意协议')
      }
      const res = await api.login({
        "code": this.sms.trim(),
        "phone": this.phoneValue.trim(),
        "wechatCode": this.code
      })
      // this.code
      console.log('loginres', res)
      if (res.code === '0') {
        // 存储token，回到首页
        localStorage.setItem('userInfo', JSON.stringify(res.data))
        localStorage.setItem('userToken', res.data.jwtToken)
        this.$router.replace('/home')
      } else {
        Toast.fail({
          duration: 2000,
          message: res.message,
        });
        setTimeout(() => {
          if (+res.code === 1000) {
            window.location.href = `${Configs.loginUrl}`;
          }
        }, 2000);
      }
    },

    start () {
      this.count = 60
      let num = 60;
      this.timer = setInterval(() => {
        if (num === 0) {
          this.sendTag = false
          clearInterval(this.timer);
          num = 60;
          this.count = num
        } else {
          num = num -= 1;
          this.count = num
        }
      }, 1000);
    },

    timerEvent () {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.start();
    },


    // 发送短信验证码
    async handleGetSms () {
      if (this.sendTag || this.sendLoading) {
        return;
      }
      if (!this.phoneValue) {
        return Toast.fail(`请输入手机号`);
      }
      if (this.phoneErrorMessage) {
        return Toast.fail(`请输入正确的手机号`);
      }

      if (!this.agreementChecked) {
        return Toast.fail(`请先阅读并同意协议`);
      }

      // 开始倒计时
      try {
        this.sendLoading = true
        const res = await api.sendSMS({
          phone: this.phoneValue && this.phoneValue.trim()
        });
        this.sendLoading = false
        if (res.code === "0") {
          this.sendTag = true
          this.timerEvent();
        } else if (res.code === "10049") {
          console.log("in error");
        } else if (res.code === "10002") {
        } else {
          Toast.fail(res.message)
        }
      } catch (error) {
        this.sendLoading = false
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding-top: 38px;
  width: 100%;
  height: 100%;
  background: #ff8352;
  text-align: center;
  .title {
    // .width: 309px;
    margin: 0 auto;
    height: 50px;
    font-weight: 600;
    font-size: 36px;
    color: #ffffff;
    line-height: 50px;
    font-style: normal;
    text-align: center;
  }
  .bg_img {
    margin-top: 8px;
    vertical-align: middle;
    width: 300px;
    height: 168px;
  }

  .login_form {
    margin: 0 auto;
    width: 340px;
    padding: 18px;
    box-sizing: border-box;
    // background: #fff;
    background: #fff;
    border-radius: 8px;
    text-align: center;

    .text {
      // width: 36px;
      margin: 0 auto 18px auto;
      height: 18px;
      font-weight: 500;
      font-size: 18px;
      color: #333333;
      line-height: 18px;
      text-align: center;
      font-style: normal;
    }

    .item_wrapper {
      width: 303px;
      height: 48px;
      background: #f5f7fa;
      border-radius: 4px;
    }

    .phone_wrapper {
      margin-bottom: 8px;
      padding: 14px 28px 14px 12px;
      box-sizing: border-box;
      /deep/ .van-field {
        padding: 0 !important;
        background: #f5f7fa !important;
      }
    }

    .verify_code_wrapper {
      padding: 14px 12px 14px 12px;
      box-sizing: border-box;
      /deep/ .van-field {
        padding: 0 !important;
        background: #f5f7fa !important;
      }
      .btn {
        display: inline-block;
        width: 75px;
        color: #0672ef;
        text-align: center;
      }
    }
  }

  // .agreement {
  //   position: fixed;
  //   bottom: 80px;
  //   height: 30px;
  // }

  .agreement_wrapper {
    display: block;
    width: 100%;
    height: 34px;
    font-size: 12px;
    padding: 0 10px;
    .agreement {
      color: #4486fb;
      font-weight: 500;
    }
  }

  .van-action-sheet {
    max-height: 95% !important;
  }

  .container {
    .agreement {
      background: #fff;
      padding: 10px;
      text-align: left;
      .bold {
        font-weight: 700;
      }
    }
  }
}
</style>